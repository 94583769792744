export const base_url = "https://api.cmstravel.pk";
// export const base_url = "http://localhost:8000";




export const altBannerImage = '/media/src/alt-banner-image.jpg';



const public_url = "https://cmstravel.pk";

export const public_url_mods = {
    tour: `${public_url}/tour-single/`,
    hajj: `${public_url}/hajj-single/`,
    visa: `${public_url}/visa-single/`,
    ticket: `${public_url}/ticket-single/`,
    transport: `${public_url}/transport-single/`,
    hotel: `${public_url}/room-single/`,
    shop: `${public_url}/store-single/`,
}



export const CKModules = {
    toolbar: [
        // [{ 'font': [] }, { 'size': ['small', 'normal', 'large', 'huge'] }],
        // [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        // [{ 'header': '1' }, { 'header': '2' }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        ['bold', 'italic', 'underline'],
        // [{ 'align': [] }],
        ['link'],
        // ['image']
    ]
};

